export var Orders_text = {
  orderlist: {
    total_earnings: 'Earnings',

    orders_title: 'Orders',
    history: 'Order History',
    date: 'Date',
    price: 'Price',
    status: 'Current Status',
    back_to_home: 'Back To Home',
    filter_title: 'Filter By :',
    canceled_by_account: 'Canceled By Account',
    customer_return_confirmed: 'Customer Return Confirmed',
    customer_return_disputed: 'Customer Return Disputed',
    customer_return_initated: 'Customer Return Initiated',
    customer_return_picked: 'Customer Return Picked',
    no_orders: 'You dont have orders',
    no_store_orders: 'You dont have Account orders',
    order_status: 'Order Status',
  },
  orderdetail: {
    shipping_label: 'Download Shipping Label',
    //
    order_status_change_confirmation:
      'Do you really want to change  the order status?',

    status: 'status',
    items_fullfilled_by_platform: 'Items fulfilled by platform',
    items_fullfilled_by_account: 'Items fulfilled by {value}',

    shipment_details: 'Shipment Tracking',
    shipment_data_unavailable_seller_note: `Enter the tracking information like provider, URL if any, and tracking reference to help your customer track their package.`,
    shipment_data_unavailable_note: `Once the seller has provided delivery tracking information, you will find the details here.`,
    add_shipment_note:
      'You can add shipment tracking only after the order status is shipped',
    add_shipment_button: 'Add Shipment Details',
    edit_shipment_button: 'Edit Shipment Details',
    provider: 'Provider',
    tracking_url: 'Tracking URL',
    tracking_number: 'Tracking Number',
    download_assets: 'Download Assets',

    order_confirmed: 'Order Confirmed',
    arrive_at_customer_location: 'Arrive at customer location',
    arrived_at_customer_location: 'Arrived at customer location',
    cancel_by_admin: 'Cancel By Admin',
    cancelled_by_admin: 'Canceled By Admin',
    order_completed: 'Order Completed',
    order_complete: 'Order Complete',
    shipment: 'Shipment',

    all: 'All',
    order_confirmed: 'Confirmed',
    cancelled_by_seller: 'Cancelled by seller',
    cancel_by_seller: 'Cancel by seller',
    title: 'Order',
    items_summary: 'Items Summary',
    qty: 'QTY',
    price: 'Price',
    total_price: 'Total price',
    customer_order_details: 'Customer & order Details',
    customer_name: 'Customer Name',
    email: 'Email Address',
    shipping_method: 'Shipping Method',
    payment_method: 'Payment Method',
    order_summary: 'Order Summary',
    order_created: 'Order Created',
    shipment_created: 'Shipment Created',
    order_time: 'Order Time',
    subtotal: 'Subtotal',
    delivery_fee: 'Delivery Fee',
    change_status: 'Change Status :',
    total_earnings: 'Total Earnings',
    grand_total: 'Grand Total',
    seller_details: 'Seller Details',
    seller_name: 'Seller Name',

    copied: 'Copied',
    no_status: 'No status for changes',
    returned: 'Returned',

    status_changed_successfully: 'Order status changed successfully',
    need_help: 'Need help on this order ?',

    address: 'Address',
    amount: 'Amount',
    amout: 'Amount',
    amt: 'Amt',
    cancel_order: 'Cancel Order',
    cancel_order_confirmation: 'Do you really want to cancel the order?',
    cancelled: 'Cancelled',
    cancelled_by_you: 'Cancelled by you',
    cancelled_by_customer: 'Cancelled by customer',
    cancel_by_customer: 'Cancel Order',
    cancelling: 'Cancelling',
    change: 'Change',
    change_order_status: 'Change Order Status',
    change_status: 'Change status',
    chat: 'Chat',
    delivery_address: 'Delivery Address',
    delivered: 'Delivered',
    deliver: 'Deliver',
    detail_order: 'Detail Order',
    incomplete: 'Incomplete',
    leave_feedback: 'Leave Feedback',
    mobile: 'Mobile',
    name: 'Name',
    no: 'No',
    no_orders_found: 'No Orders found',
    order_cancelled: 'Order Cancelled',
    order_cancelled_successfully: 'Order cancelled successfully!',
    order_delivered: 'Order Delivered',
    order_deliver: 'Order Deliver',
    shipment_delivered: 'Shipment Delivered',
    shipment_deliver: 'Shipment Deliver',
    order_detail: 'Order Detail',
    order_details: 'Order Details',
    order_id: 'Order ID',
    order_in_progress: 'Order In Progress',
    shipment_in_progress: 'Shipment In Progress',
    order_placed: 'Order placed',
    order_place: 'Order place',
    order_placed_successfully: 'Order Placed Successfully',
    order_return_confirmed: 'Order Return Confirmed',
    shipment_return_confirmed: 'Shipment Return Confirmed',
    shipment_return_confirm: 'Shipment Return Confirm',
    order_return_disputed: 'Order Return Disputed',
    order_status_incomplete: 'Order Status incomplete',
    out_for_delivery: 'Out for Delivery',
    pending: 'Pending',
    ready_for_pickup: 'Ready For pickup',
    reorder: 'Reorder',
    return_initiated: 'Return Initiated',
    return_initiate: 'Return Initiate',
    return_pickedup: 'Return Picked Up',
    return_pickup: 'Return Pick Up',
    review_all_product: 'Review All Listings',
    review_product: 'Review Listing',
    see_details: 'See Details',
    select_status: 'Select Status',
    shipped: 'Shipped',
    ship: 'Ship',
    track_order: 'Track Order',
    undelivered_return_confirmed: 'Undelivered Return Confirmed',
    undelivered_returned: 'Undelivered Returned',
    undelivered_return: 'Undelivered Return',
    units: 'Units',
    unreachable: 'Customer unreachable',
    yes: 'Yes',
    orderdetail_delivery_confirmed: 'Delivery Confirmed',
    shipment_delivery_confirm: 'Delivery Confirm',
    shipment_delivery_confirmed: 'Delivery Confirmed',
    orderdetail_return_pickedup: 'Return Picked Up',
  },
};
