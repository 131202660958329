import React from 'react';

const DownloadImage = ({ downloadImage }) => {
  return (
    <div className=" flex flex-wrap items-center justify-between mt-2">
      <button className=" btn-primary-outline " onClick={downloadImage}>
        Download as Image
      </button>
      <button
        className="btn-primary-outline "
        onClick={() => downloadImage({ type: 'pdf' })}
      >
        Download as PDF
      </button>
      {/* <button className="btn-primary-outline " onClick={() => addToCalendar()}>
        Add to Calendar
      </button> */}
    </div>
  );
};

export default DownloadImage;
