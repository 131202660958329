import React from 'react';
import BookingTicket from './BookingTicket';

const TicketList = ({ order_details }) => {
  const ticket_list =
    order_details?.order_details?.[0]?.order_detail_item_attributes;

  return (
    <div className=" space-y-4">
      {ticket_list?.map((ticket) => {
        return (
          <BookingTicket
            order_details={order_details}
            ticket_attribute={ticket}
          />
        );
      })}
    </div>
  );
};

export default TicketList;
